.sidebar {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
        ),
        #121212;
    @apply fixed top-0 inline-block h-screen w-[260px] py-2 shadow-sidebar-shadow ipad:z-[91];
}
.sidebarLogo {
    @apply relative mb-4 w-full px-2 pl-4 text-left;
}
.userSection {
    @apply cursor-pointer;
}
.userSection .signOut {
    @apply relative mx-2 mb-2 px-10 py-3 text-common-white;
}
.userSection .signOut i {
    @apply absolute top-0 left-2 flex h-full items-center text-lg text-interface-400;
}
.userSection .userLoggedIn {
    @apply mx-2 rounded-md bg-theme-dark-500 p-[8px];
}
.sidebar li i {
    @apply mr-4 text-[18px] text-interface-400;
}
.sidebar li.active i {
    @apply text-common-white;
}
.sidebarNavigation {
    @apply h-[calc(100%-170px)] overflow-auto px-2 ipad:h-[calc(100%-180px)];
}
.sidebar ul {
    @apply list-none bg-transparent;
}
.sidebar li {
    @apply relative mb-2 w-full cursor-pointer list-none px-2 py-2 text-sm font-normal text-common-white;
}
.sidebar li.active {
    @apply rounded-md bg-theme-dark-900 py-2 text-common-white;
}
.sidebar li.active a {
    @apply text-common-white;
}
.activeArrow {
    @apply hidden;
}
.sidebar li.active .activeArrow {
    @apply block;
}
