@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?f4zy1i');
    src: url('/fonts/icomoon.eot?f4zy1i#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?f4zy1i') format('truetype'),
        url('/fonts/icomoon.woff?f4zy1i') format('woff'),
        url('/fonts/icomoon.svg?f4zy1i#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cancel-circle:before {
    content: '\ea0d';
}
.icon-cross:before {
    content: '\ea0f';
}
.icon-list:before {
    content: '\e9bb';
}
.icon-block:before {
    content: '\e900';
}
.icon-crosshair:before {
    content: '\e901';
}
.icon-email:before {
    content: '\e902';
}
.icon-eye-none:before {
    content: '\e903';
}
.icon-flag:before {
    content: '\e904';
}
.icon-briefcase:before {
    content: '\e905';
}
.icon-drag:before {
    content: '\e906';
}
.icon-status:before {
    content: '\e907';
}
.icon-chevron-right:before {
    content: '\e908';
}
.icon-more:before {
    content: '\e90a';
}
.icon-trash:before {
    content: '\e914';
}
.icon-arrow-left-2:before {
    content: '\e909';
}
.icon-check:before {
    content: '\e90b';
}
.icon-configuration:before {
    content: '\e90c';
}
.icon-featured-content:before {
    content: '\e90d';
}
.icon-gift:before {
    content: '\e90e';
}
.icon-inactive:before {
    content: '\e90f';
}
.icon-info:before {
    content: '\e910';
}
.icon-live-stream:before {
    content: '\e911';
}
.icon-log-out:before {
    content: '\e912';
}
.icon-manage-content:before {
    content: '\e913';
}
.icon-online:before {
    content: '\e915';
}
.icon-pie-chart:before {
    content: '\e916';
}
.icon-platform-user:before {
    content: '\e917';
}
.icon-refresh:before {
    content: '\e918';
}
.icon-search:before {
    content: '\e919';
}
.icon-star:before {
    content: '\e91a';
}
.icon-star-active:before {
    content: '\e91b';
}
.icon-thick-arrow-left:before {
    content: '\e91c';
}
.icon-thick-arrow-right:before {
    content: '\e91d';
}
.icon-user:before {
    content: '\e91e';
}
.icon-notification:before {
    content: '\e91f';
}
.icon-down-arrow:before {
    content: '\e920';
}
.icon-close:before {
    content: '\e921';
}
.icon-file:before {
    content: '\e922';
}
.icon-clipboard:before {
    content: '\e923';
}
.icon-image:before {
    content: '\e924';
}
.icon-hamburger:before {
    content: '\e925';
}
.icon-share:before {
    content: '\e926';
}
.icon-calendar:before {
    content: '\e927';
}
.icon-cat:before {
    content: '\e928';
}
.icon-double-arrow:before {
    content: '\e929';
}
.icon-dribbble:before {
    content: '\e92a';
}
.icon-edit:before {
    content: '\e92b';
}
.icon-envelope:before {
    content: '\e92c';
}
.icon-facebook:before {
    content: '\e92d';
}
.icon-gift1:before {
    content: '\e92e';
}
.icon-globe:before {
    content: '\e92f';
}
.icon-info-circle:before {
    content: '\e930';
}
.icon-instagram:before {
    content: '\e931';
}
.icon-left-arrow:before {
    content: '\e932';
}
.icon-pause:before {
    content: '\e933';
}
.icon-person:before {
    content: '\e934';
}
.icon-play:before {
    content: '\e935';
}
.icon-rating:before {
    content: '\e936';
}
.icon-right-arrow:before {
    content: '\e937';
}
.icon-search1:before {
    content: '\e938';
}
.icon-sound-off:before {
    content: '\e939';
}
.icon-sound-on:before {
    content: '\e93a';
}
.icon-sticker:before {
    content: '\e93b';
}
.icon-streaming:before {
    content: '\e93c';
}
.icon-telephone:before {
    content: '\e93d';
}
.icon-twitter:before {
    content: '\e93e';
}
