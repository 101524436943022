@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
html {
    @apply font-normal bg-theme-dark-900 text-common-white;
}
body {
    @apply overflow-x-hidden text-sm font-normal font-theme text-common-white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply mb-4 font-semibold;
}
h1,
.h1 {
    @apply text-3xl border-opacity-100;
}
.h2,
h2 {
    @apply text-2xl;
}
.h3,
h3 {
    @apply text-xl;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base;
}
.h6,
h6 {
    @apply text-sm;
}
ul {
    @apply relative;
}
ol li {
    @apply text-sm list-decimal list-inside;
}
a {
    @apply text-primary-500;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}
.scrollable,
.draging-list > div {
    @apply overflow-auto;
}
.scrollable::-webkit-scrollbar-track,
.draging-list > div::-webkit-scrollbar-track,
.infinite-scroll-component::-webkit-scrollbar-track,
.infinite-scroll-component > div::-webkit-scrollbar-track,
.style-table-two .style-table-inner::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
.custom-select > div > div::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-interface-800;
}
.scrollable::-webkit-scrollbar,
.draging-list > div::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.infinite-scroll-component > div::-webkit-scrollbar,
.style-table-two .style-table-inner::-webkit-scrollbar,
textarea::-webkit-scrollbar,
.custom-select > div > div::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px];
}
.scrollable::-webkit-scrollbar-thumb,
.draging-list > div::-webkit-scrollbar-thumb,
.infinite-scroll-component::-webkit-scrollbar-thumb,
.style-table-two .style-table-inner::-webkit-scrollbar-thumb,
.infinite-scroll-component > div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
.custom-select > div > div::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-700;
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar-track {
    @apply rounded-[50px] bg-interface-800;
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar {
    @apply h-[6px] w-[4px] rounded-[50px];
}
.modal-table-container .infinite-scroll-component::-webkit-scrollbar-thumb {
    @apply rounded-[50px] bg-interface-700;
}
/* slider setting */
.css-1fzpoyk {
    @apply block w-[530px] opacity-100 !important;
}
.css-1fzpoyk .cover-photo-image {
    @apply opacity-50;
}
.css-1fzpoyk[style='transform: translateY(-50%) translateX(-50%) scale(1); left: 50%; opacity: 1; z-index: 2;']
    .cover-photo-image {
    @apply opacity-100;
}
.cover-photo-image iframe {
    @apply w-full h-full;
}
.scroll-visible > div {
    @apply overflow-x-visible overflow-y-visible;
}
.scroll-x-visible {
    @apply overflow-visible ipad:overflow-auto;
}
.scroll-x-visible > div {
    @apply overflow-x-visible overflow-y-visible 2xl:overflow-x-auto 2xl:overflow-y-auto;
}
.table-placeholder > div {
    @apply overflow-x-hidden;
}
/* tooltip */
.tooltip {
    @apply relative grid place-items-center text-common-white;
}
.tooltip-content-top {
    transform: translateY(-100%);
}
.tooltip-content {
    @apply invisible absolute -top-[10px] z-[99] w-max min-w-[auto] max-w-[290px] rounded-md bg-interface-700 px-2 py-2 text-sm text-common-white opacity-0 shadow-2xl;
}
.tooltip-content ul li {
    @apply inline-block mb-1 mr-1 last-of-type:mb-0;
}
.tooltip-content ul li .list-tag {
    @apply inline-block rounded-sm bg-theme-dark-100 px-[6px] py-[2px] align-top;
}
.tooltip-content::before {
    content: '';
    @apply absolute aspect-square w-[10px] bg-interface-700;
}
.tooltip-content-top::before {
    transform: translateX(-50%) rotate(45deg);
    @apply -bottom-[5px] left-2/4;
}
.tooltip:hover .tooltip-content {
    transition: opacity 0.1s ease-in;
    @apply visible opacity-100;
}
.table-placeholder .tooltip {
    @apply hidden;
}
.main-slider > div > div {
    @apply first-of-type:hidden last-of-type:hidden !important;
}
.css-1qzevvg {
    @apply absolute left-0 top-0 bottom-0 mt-0 h-full w-full items-center justify-between !important;
}
.css-1qzevvg:before {
    content: url('/storage/navigation/left-arrow.svg');
    @apply visible relative -left-[150px] w-[24px] 3xl:-left-[200px] xl:-left-[225px];
}
.css-1qzevvg:after {
    content: url('/storage/navigation/right-arrow.svg');
    @apply visible relative -right-[150px] w-[24px] 3xl:-right-[200px] xl:-right-[225px];
}
.css-1qzevvg img:nth-child(1) {
    @apply absolute -left-[150px] z-[1] h-10 cursor-pointer opacity-0 3xl:-left-[200px] xl:-left-[230px];
}
.css-1qzevvg img:nth-child(2) {
    @apply absolute -right-[150px] z-[1] h-10 cursor-pointer opacity-0 3xl:-right-[200px] xl:-right-[230px];
}
body .live-chat-input {
    @apply pr-[50px] focus:border-interface-700;
}
.page-heading a {
    @apply text-primary-500;
}
/* Live Streaming */
.video-js {
    @apply w-full;
}
body .vjs-poster {
    @apply bg-cover;
}
.zoom-effect {
    transition: transform 0.3s;
    @apply hover:scale-105;
}
/* =============
    Card
=============== */
.default-card {
    @apply p-6 mb-6 rounded-md shadow-md bg-theme-dark-100;
}
.default-card.sticker-card {
    @apply p-2.5;
}
.sticker-delete.sticker-delete {
    @apply top-[7px] right-[7px] z-[5] bg-live opacity-0;
}
.sticker-delete i {
    @apply text-common-white;
}
.sticker-card:hover .sticker-delete {
    @apply opacity-100;
}
.default-card-action-pos {
    @apply absolute text-lg cursor-pointer right-4 top-4 text-interface-600;
}
.default-card-action-icon {
    @apply relative p-3 text-lg cursor-pointer text-interface-600;
}
.dashboard-block-heading {
    @apply mb-[10px] text-sm uppercase;
}
.table-font-small .rdt_TableHeadRow div {
    @apply text-xs;
}
.default-react-select .css-26l3qy-menu div div {
    @apply max-h-[calc(100vh-290px)] border-b border-interface-700 pb-[10px] last-of-type:border-0;
}
.table-placeholder.table-placeholder .rdt_TableCell div {
    @apply whitespace-nowrap text-interface-700;
}
.data-loader svg {
    @apply w-8 h-8;
}
/* Custom Select */
.dragable-listing .default-react-select .css-1okebmr-indicatorSeparator,
.dragable-listing .default-react-select svg,
.dragable-listing .default-react-select .css-at12u2-loadingIndicator {
    @apply hidden;
}
.default-react-select .css-1s2u09g-control {
    @apply h-auto overflow-hidden text-sm font-normal border rounded-md border-interface-600 bg-interface-800 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.default-react-select.default-react-select
    .css-1s2u09g-control
    > div:last-child,
.default-react-select.default-react-select
    .css-1pahdxg-control
    > div:last-child {
    @apply hidden;
}
.default-react-select input {
    @apply text-common-white !important;
}
.default-react-select .css-26l3qy-menu {
    @apply absolute top-auto left-0 z-[999] bg-interface-800 pb-0 shadow-none;
}
.default-react-select .css-26l3qy-menu .css-4ljt47-MenuList {
    @apply py-0;
}
.default-react-select .css-1n7v3ny-option {
    @apply bg-interface-700;
}
.default-react-select .css-319lph-ValueContainer {
    @apply h-[38px] rounded-md  bg-interface-800 py-0 pl-2 pr-2;
}
.default-react-select .css-1n7v3ny-option:active {
    @apply bg-interface-900;
}
.default-react-select .css-hxdyr0-control,
.default-react-select .css-jtl9jd-control {
    @apply bg-transparent pl-7 text-common-white;
}
.default-react-select .css-14el2xx-placeholder {
    @apply text-common-white;
}
.default-react-select .css-1rhbuit-multiValue {
    @apply rounded-[4px] bg-interface-700 text-common-white;
}
.default-react-select .css-12jo7m5,
.default-react-select .css-qc6sy-singleValue {
    @apply text-common-white;
}
.default-react-select .css-1pahdxg-control {
    @apply h-auto shadow-none outline-none border-interface-600 bg-interface-800 hover:border-interface-600;
}
.default-react-select .css-xb97g8 {
    @apply hover:bg-transparent hover:text-common-white;
}
.default-react-select .css-1pndypt-Input {
    @apply text-base font-normal text-common-white focus:border-interface-300 focus:shadow-none focus:outline-none;
}
/* phone field */
.PhoneInputCountry.PhoneInputCountry {
    @apply hidden;
}
.PhoneInputInput.PhoneInputInput {
    @apply bg-transparent py-[8px] outline-none placeholder:text-interface-400;
}
/* =============
    Datepicker
=============== */
.date-picker-form-control input,
.PhoneInput input {
    @apply h-[46px] w-full rounded-md border border-interface-600 bg-interface-800 px-3 pb-[4px] text-base text-common-white placeholder:text-interface-400 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.disabled-timepicker .date-picker-form-control {
    @apply bg-interface-800;
}
.form-check > input {
    @apply top-0 left-0 z-[1] block h-4 w-4 cursor-pointer opacity-0;
}
.refresh-icon button i {
    animation: spin 1s linear infinite;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
.disabled-timepicker .date-picker-form-control input {
    @apply text-interface-400;
}
.disabled-timepicker .date-picker-form-control i {
    @apply text-interface-400;
}
.custom-date-time .react-datepicker-wrapper {
    @apply h-[36px];
}
.custom-date-time .react-datepicker__input-container input {
    @apply h-full w-full bg-transparent pb-[1px] text-sm font-normal outline-none placeholder:text-common-white;
}
.custom-date-time .react-datepicker__input-container {
    @apply w-full h-full;
}
.custom-date-time .react-datepicker__header {
    @apply border-b border-interface-600 bg-interface-900 text-common-white;
}
.custom-date-time .react-datepicker__current-month,
.custom-date-time .react-datepicker__day-name {
    @apply text-common-white;
}
.custom-date-time .react-datepicker,
.custom-date-time .react-datepicker__year-dropdown,
.custom-date-time .react-datepicker__month-dropdown,
.custom-date-time .react-datepicker__month-year-dropdown {
    @apply border border-interface-600 bg-interface-800 text-common-white;
}
.custom-date-time .react-datepicker__day {
    @apply text-common-white;
}
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
.custom-date-time
    .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    @apply border-b-interface-600;
}
.custom-date-time .react-datepicker__year-read-view--down-arrow {
    @apply hidden;
}
.custom-date-time .react-datepicker__day--keyboard-selected,
.custom-date-time .react-datepicker__month-text--keyboard-selected,
.custom-date-time .react-datepicker__quarter-text--keyboard-selected,
.custom-date-time .react-datepicker__year-text--keyboard-selected,
.custom-date-time .react-datepicker__day--selected,
.custom-date-time .react-datepicker__day--in-selecting-range,
.custom-date-time .react-datepicker__day--in-range,
.custom-date-time .react-datepicker__month-text--selected,
.custom-date-time .react-datepicker__month-text--in-selecting-range,
.custom-date-time .react-datepicker__month-text--in-range,
.custom-date-time .react-datepicker__quarter-text--selected,
.custom-date-time .react-datepicker__quarter-text--in-selecting-range,
.custom-date-time .react-datepicker__quarter-text--in-range,
.custom-date-time .react-datepicker__year-text--selected,
.custom-date-time .react-datepicker__year-text--in-selecting-range,
.custom-date-time .react-datepicker__year-text--in-range {
    @apply bg-primary-500;
}
.custom-date-time .react-datepicker__day,
.custom-date-time .react-datepicker__month-text,
.custom-date-time .react-datepicker__quarter-text,
.custom-date-time .react-datepicker__year-text {
    @apply hover:bg-interface-500;
}
.custom-date-time .react-datepicker__year-option,
.custom-date-time .react-datepicker__month-option,
.custom-date-time .react-datepicker__month-year-option {
    @apply py-[5px] hover:bg-interface-500;
}
.custom-date-time .react-datepicker__navigation--years-upcoming,
.custom-date-time .react-datepicker__navigation--years-previous {
    @apply relative;
}
.custom-date-time .react-datepicker__navigation--years-upcoming:after,
.custom-date-time .react-datepicker__navigation--years-previous:after {
    content: '';
    @apply absolute right-0 left-[9px] top-3.5 h-[10px] w-[10px] -rotate-45 border-t-[3px] border-r-[3px] border-solid border-common-white;
}
.custom-date-time .react-datepicker__navigation--years-previous:after {
    @apply bottom-4 top-auto rotate-[134deg];
}
/* scondary modal */
.secondary-modal {
    @apply overflow-hidden rounded-md bg-interface-700;
}
.sticker-title {
    @apply mb-6 text-lg;
}
.secondary-modal h2 span {
    @apply text-base font-semibold text-left;
}
.secondary-modal .btnClose {
    @apply absolute right-4 top-[5px];
}
.secondary-modal .default-card {
    @apply mb-0 border border-interface-700 bg-theme-dark-100 p-[7px];
}
.input-thumbnail {
    @apply absolute left-0 w-full h-full opacity-0 cursor-pointer;
}
.input-thumbnail:checked + .default-card {
    @apply border cursor-pointer border-primary-500 bg-theme-dark-900;
}
.input-thumbnail:checked + .default-card .amount {
    @apply text-primary-500;
}
.rdt_Table .user-streamer {
    @apply text-common-white;
}
.text-placeholders,
.content-placeholders-animation,
.content-placeholders-animation .status-loader.status-loader,
.text-placeholders .status-loader.status-loader,
.table-placeholder.table-placeholder .btn div span,
.table-placeholder.table-placeholder .user-streamer {
    @apply animate-pulse font-redacted text-interface-700;
}
.input-thumbnail:checked + .default-card .amount span {
    @apply text-common-white;
}
.live-chat-message {
    @apply pointer-events-none;
}
.live-chat-message.live-chat-online {
    @apply cursor-pointer pointer-events-auto;
}
.live-chat-message.live-chat-online .live-chat-input {
    @apply bg-interface-700;
}
/* Table */
.base-table {
    @apply overflow-hidden border rounded-md border-theme-dark-100;
}
.base-table.overflow-none {
    @apply overflow-visible ipad:overflow-auto;
}
.base-table.overflow-none .infinite-scroll-component div:first-child {
    @apply overflow-visible;
}
.rdt_Table.rdt_Table {
    @apply bg-transparent;
}
.style-table-two .rdt_TableHeadRow {
    @apply border-interface-700;
}
.table-placeholder .rdt_TableHeadRow {
    @apply flex items-center w-full text-theme-dark-100;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow {
    @apply min-h-[36px] bg-theme-dark-100;
}
.rdt_Table .text-placeholders {
    @apply text-interface-400;
}
/* checkbox */
.form-check {
    @apply relative w-4 h-4;
}
.form-check > * {
    @apply absolute;
}
.checkbox-section {
    @apply w-4 h-4 overflow-hidden border rounded-sm border-interface-600 bg-interface-800;
}
.form-check > input:checked + .checkbox-section {
    background-image: url(/storage/check.svg);
    background-size: 81%;
    @apply h-[15px] w-[15px] border border-primary-500 bg-primary-500 bg-center bg-no-repeat;
}
.form-check > input[disabled] {
    @apply opacity-50 cursor-not-allowed;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .form-check {
    @apply absolute -top-[56px] left-0 w-[60px];
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .form-check > input {
    @apply w-full;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .form-check .checkbox-section {
    @apply w-[60px] opacity-0;
}
.rdt_Table .rdt_TableCell {
    @apply px-2;
}
.manage-content-table
    .rdt_TableRow
    .rdt_TableCell:nth-child(4)
    div:first-child {
    @apply overflow-visible;
}
.table-placeholder .rdt_TableCol {
    @apply w-auto;
}
.rdt_Table .rdt_TableHead .rdt_TableCol {
    @apply px-2 text-sm font-semibold normal-case bg-theme-dark-100 text-common-white;
}
.rdt_Table .rdt_TableRow {
    @apply min-h-[40px] bg-theme-dark-900 text-common-white even:bg-theme-dark-500;
}
.table-dropdown {
    @apply absolute right-0 z-[1] mt-[5px] w-[178px] origin-top-right overflow-hidden rounded-[4px] rounded-t-none bg-interface-700 shadow-lg outline-none focus:outline-none;
}
.rdt_Table .rdt_TableRow:nth-last-child(-n + 2) .table-dropdown {
    @apply top-auto bottom-[34px] mt-0 rounded-t-[4px] rounded-b-none !important;
}
.rdt_Table .rdt_TableRow:nth-child(-n + 10) .table-dropdown {
    @apply top-[34px] bottom-auto mt-0 rounded-[4px] rounded-t-none;
}
.abuse-report-table .rdt_Table .rdt_TableRow .table-dropdown {
    @apply top-[34px] bottom-auto mt-0 rounded-[4px] rounded-t-none;
}
.color-white-text tspan {
    @apply fill-common-white;
}
.card-lists {
    @apply p-2 mb-1 border rounded-md cursor-pointer border-theme-dark-500 bg-theme-dark-500 hover:border-primary-500 hover:bg-interface-900;
}
.card-lists.profile-card {
    @apply bg-theme-dark-100;
}
.delete-section {
    @apply absolute top-0 right-0 flex items-center justify-center w-5 h-5 rounded-full bg-theme-dark-900 text-interface-500;
}
.selected-option .delete-section {
    @apply bg-live text-common-white;
}
.card-lists:hover .delete-section {
    @apply bg-live text-common-white;
}
.delete-list {
    @apply text-interface-500;
}
.card-lists:hover .delete-list {
    @apply text-common-white;
}
.bg-drop {
    @apply absolute top-0 left-0 flex items-center justify-center w-full h-full overflow-hidden opacity-0 cursor-move bg-common-black;
}
.selected-option .bg-drop,
.card-lists:hover .bg-drop {
    @apply opacity-70;
}
.selected-option {
    @apply border-primary-500 bg-interface-900;
}
.icon-bullet {
    @apply h-[6px] w-[6px] rounded-md border-0 bg-common-white;
}
.style-table-two {
    @apply rounded-xl bg-theme-dark-100;
}
.style-table-two .base-table .eSTlnH {
    @apply bg-transparent mobile:w-[560px];
}
.table-placeholder .rdt_TableRow {
    @apply flex h-[45px] w-full items-center justify-center;
}
.style-table-two .base-table .rdt_TableRow {
    @apply py-2 border-interface-700 bg-theme-dark-100;
}
.select-all-table input[name='select-all-rows'] {
    @apply pointer-events-none;
}
.scroll-visible {
    @apply overflow-visible ipad:overflow-auto;
}
.mobile-chart.mobile-chart.mobile-chart {
    @apply mobile:h-[250px] !important;
}
.streamer-chart text {
    @apply fill-common-white;
}
.custom-selectbox select {
    @apply pr-[20px];
}
/* =============
    Table Placeholder
=============== */
.table-placeholder {
    @apply font-redacted;
}
.table-placeholder .rdt_Table .rdt_TableBody .rdt_TableRow.rdt_TableRow {
    @apply font-redacted text-interface-700;
}
.table-placeholder .rdt_TableHead {
    @apply flex flex-col items-center justify-center w-full h-full;
}
.table-placeholder .rdt_TableBody {
    @apply flex flex-col;
}
.table-placeholder .rdt_TableCell {
    @apply w-auto;
}
.table-placeholder
    .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol.rdt_TableCol,
.table-placeholder a,
.table-placeholder .status-loader {
    @apply font-redacted text-interface-700;
}
.table-placeholder .status-loader.status-loader {
    @apply text-opacity-0 bg-interface-700;
}
.table-placeholder .rdt_Table .rdt_TableCell {
    @apply animate-pulse font-redacted text-interface-700;
}
.table-placeholder.table-placeholder i {
    @apply inline-block w-4 h-4 text-opacity-0 animate-pulse bg-interface-700;
}
.table-placeholder.table-placeholder.table-placeholder img,
.table-placeholder.table-placeholder.table-placeholder .text-placeholder {
    @apply hidden !important;
}
.stickers-scrolling .infinite-scroll-component {
    @apply overflow-hidden !important;
}
.data-loader svg path {
    @apply fill-primary-500;
}
.upload-sticker-image {
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
        ),
        #121212;
    @apply rounded-md;
}
.custom-infinite-scrolling .infinite-scroll-component {
    @apply overflow-visible !important;
}
.toast-ui .Toastify__toast-theme--colored.Toastify__toast--success,
.toast-ui .Toastify__progress-bar--success.Toastify__progress-bar--success {
    @apply bg-success-700;
}
.toast-ui .Toastify__toast-container {
    @apply w-auto;
}
.toast-ui .Toastify__toast-icon,
.toast-ui .Toastify__close-button {
    @apply hidden;
}
.toast-ui .Toastify__toast {
    @apply min-h-0 py-0 rounded-md;
}
.taging-section {
    @apply whitespace-nowrap !important;
}
.dragable-listing .default-react-select {
    @apply border rounded-md border-interface-600 bg-interface-700;
}
.dragable-listing .default-react-select.disabled {
    @apply bg-transparent pointer-events-none;
}
.dragable-listing .default-react-select.disabled .css-14el2xx-placeholder {
    @apply text-interface-500;
}
.dragable-listing .default-react-select .css-319lph-ValueContainer {
    @apply bg-transparent;
}
.content-width.content-width {
    @apply w-full;
}
.following-followers .infinite-scroll-component {
    @apply pr-[16px];
}
/* .dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(1)
    .table-dropdown {
    @apply fixed top-[221px] bottom-auto right-8 rounded-[4px] rounded-t-none !important;
}
.dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(2)
    .table-dropdown {
    @apply fixed top-[263px] right-8 bottom-auto rounded-[4px] rounded-t-none !important;
}
.dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(3)
    .table-dropdown {
    @apply fixed top-[300px] right-8 bottom-auto rounded-[4px] rounded-t-none !important;
} */
.dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(1)
    .table-dropdown.manage-system-user {
    @apply absolute right-0 top-[40px] !important;
}
.dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(2)
    .table-dropdown.manage-system-user {
    @apply absolute right-0 top-[28px] !important;
}
.dropdown-table-td.dropdown-table-td
    .rdt_Table
    .rdt_TableRow:nth-child(3)
    .table-dropdown.manage-system-user {
    @apply absolute right-0 top-[40px] !important;
}
.platform-users-loader {
    @apply ipad:h-[calc(100vh-129px)] !important;
}
.top-content .table-placeholder {
    @apply absolute top-0 w-full;
}
.dashboard-table .base-table {
    @apply px-4;
}
.abuse-live-stream {
    @apply w-[90%] overflow-hidden text-ellipsis whitespace-nowrap !important;
}
.fixed-dropdown .rdt_Table .rdt_TableRow:nth-child(1) .table-dropdown {
    @apply fixed right-[67px] top-[163px] bottom-auto !important;
}
@tailwind utilities;
