.errorPage {
    @apply fixed h-screen w-full;
}
.errorPage .inner {
    @apply flex h-screen w-full items-center justify-center;
}
.errorPage .heading {
    @apply mb-16  font-bold text-[120px] text-primary-500;
}
.errorPage .subHeading {
    @apply text-4xl ipad:text-[30px];
}
.errorPage .description {
    @apply mx-auto mb-0 max-w-[700px] text-lg text-interface-400;
}
.imagePosition {
    @apply flex h-screen w-full items-center justify-center;
}
.aeroplance {
    @apply absolute top-[-170px] left-0 right-[-140px] mx-auto hidden w-[350px];
}
.downloadBody {
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.2));
    @apply m-auto min-w-[480px] rounded-xl border overflow-hidden border-interface-700 bg-theme-dark-900 text-center;
}
.bgDownload {
    @apply bg-center w-full h-full bg-contain bg-no-repeat fixed -bottom-[250px] left-0 right-0 -z-10;
}