.authLogin {
    background-position: 100% 100%;
    @apply h-[100vh] w-full overflow-auto bg-contain bg-no-repeat ipad:flex ipad:items-center mobile:flex mobile:bg-cover mobile:px-8;
}
.authContainer {
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.14))
        drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.2));
    @apply m-auto mt-[4%] max-w-[480px] overflow-hidden rounded-xl border border-interface-700 bg-theme-dark-900 ipad:mt-auto ipad:w-full mobile:m-auto mobile:mt-auto mobile:w-full mobile:max-w-none;
}
